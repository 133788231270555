import * as React from 'react';

import PropTypes from 'prop-types';

import useMedias from '../../../hooks/useMedias';

import './VisuelTextOverlappedBlock.scss';

let classNames = require('classnames');

const VisuelTextOverlappedBlock = ({
  extremeLeft,
  extremeRight,
  visuel,
  alt,
  title,
  text,
  bgColor,
}) => {
  const { processUrl } = useMedias();

  return (
    <div className={classNames('visuel_text_overlapped_block')}>
      <div
        className={classNames({ wrapper_page: extremeLeft || extremeRight })}
      >
        <div
          className={classNames(
            { cross_wrapper_extreme_left: extremeLeft },
            { cross_wrapper_extreme_right: extremeRight }
          )}
        >
          <div className="text_visuel">
            <div className="visuel">
              {visuel && (
                <img
                  src={processUrl(visuel?.image_style_uri?.gatsby_large)}
                  alt={alt}
                  style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                  aria-hidden="true"
                />
              )}
            </div>
            <div className="text_container">
              <div
                className={classNames('text', bgColor)}
                dangerouslySetInnerHTML={{ __html: text }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

VisuelTextOverlappedBlock.defaultProps = {
  title: 'Default Title',
  text: 'Default text',
  alt: '',
  bgColor: 'color_dark_bleu',
};

VisuelTextOverlappedBlock.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};

export default VisuelTextOverlappedBlock;
