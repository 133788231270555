import React from 'react';

import {
  MaqsueBannerPage,
  MaqsueBannerPageMobile,
  MaqsueBannerPageTablet,
} from '../../Atoms/Icons/Icons';
import useMedias from '../../hooks/useMedias';
import ScrollIcon from '../../Molecules/ScrollIcon/ScrollIcon';

import './PageBanner.scss';

let classNames = require('classnames');

const PageBanner = ({
  visuel,
  alt,
  scroll,
  backgroundColor,
  children
}) => {
  const { processUrl } = useMedias();

  return (
    <div className="page_banner">
      <div className="visuel">
        {visuel && (
          <>
            <img
              className="only_desktop"
              src={processUrl(visuel?.image_style_uri?.gatsby_wide)}
              alt={alt || ""}
              style={{
                height: '100%',
                width: '100%',
                objectFit: 'cover',
                position: 'absolute',
              }}
              aria-hidden="true"
            />
            <img
              className="only_mobile_tablet"
              src={processUrl(visuel?.image_style_uri?.gatsby_medium)}
              alt={alt || ""}
              style={{ width: '100%', height: '500px', objectFit: 'cover' }}
              aria-hidden="true"
            />
          </>
        )}
        <MaqsueBannerPage
          className={classNames(
            'shape_yellow_banner_bottom',
            'only_desktop',
            backgroundColor || ''
          )}
        />
        <MaqsueBannerPageMobile
          className={classNames(
            'shape_yellow_banner_bottom',
            'only_mobile',
            backgroundColor || ''
          )}
        />
        <MaqsueBannerPageTablet
          className={classNames(
            'shape_yellow_banner_bottom',
            'only_tablet',
            backgroundColor || ''
          )}
        />
      </div>
      <div className="visuel_overlay">
        {children}
        {scroll && (
          <div className="scroll_icon_container">
            <ScrollIcon />
          </div>
        )}
      </div>
    </div>
  );
};

export default PageBanner;
